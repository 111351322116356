import React from 'react';
import { Cards, Chart, CountryPicker, Credits, ResetButton} from './components';
import styles from './App.module.css';
import { fetchData } from './api';
import { fetchDailyData } from './api';
import coronaImage from './images/covid.png';

class App extends React.Component {
    state = {
        data: {},
        country: '',
    }

   async componentDidMount() {
        const fetchedData = await fetchData();
        const fetchedDailyData = await fetchDailyData();
     //   console.log(fetchedDailyData);
        //console.log(fetchedData);
        this.setState({data: fetchedData});
    }
    handleCountryChange = async (country) => {
     //   console.log(country);
        const fetchedData = await fetchData(country);
        this.setState({data: fetchedData, country: country});
    }
    handleReset = async () => {
        const fetchedData = await fetchData();
        const fetchedDailyData = await fetchDailyData();
        this.setState({data: fetchedData});
        this.setState({country: ''});
        this.handleCountryChange('');
    }
    render() { 
        const { data, country } = this.state;
        console.log('render data '+data);
            return(
                <div className={styles.container}>
                    <img className={styles.image} src={coronaImage} alt="Covid" />
                   <Cards data={data} />
                    <CountryPicker handleCountryChange={this.handleCountryChange} />

                    <ResetButton handleReset={this.handleReset} />
                    <Chart data={data} country={country} />
                    <Credits />
                </div>
            )
    };
}

export default App;