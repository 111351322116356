import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Badge } from '@material-ui/core';
import styles from './Credits.module.css';
import CountUp from 'react-countup';
import cx from 'classnames';
import k from '../../images/k.jpg';
import r from '../../images/r.jpg'

const Credits = () =>{
return (
    <div className={styles.container}>
    <Grid container spacing = {6} justify="">
        <Grid item  xs={12} md={12} >
            <Typography variant="h5">Developed by:</Typography>
        </Grid>
        <Grid item  xs={12} md={6} >
            <Badge color="secondary" >
                <Avatar className={styles.ik} src={k} alt="Kingshuk"/>
                <Typography className={styles.txt} variant="h5">KINGSHUK</Typography>
            </Badge>
        </Grid>
     {/*    <Grid item xs={12} md={6} >
            <Badge color="secondary" >
                <Avatar className={styles.ir} src={r} alt="Ritoja"/>
                <Typography className={styles.txt} variant="h5">RITOJA</Typography>
            </Badge> 
        
        </Grid>*/}
    </Grid>
    </div>
)
};

export default Credits;