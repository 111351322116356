import axios from 'axios';
import { CountryPicker } from '../components';

const url = "https://covid19.mathdro.id/api";

export const fetchData = async (country) => {
    let changeableUrl = url;
    if(country) {
        changeableUrl = url+"/countries/"+country;
    }

    try {
        const { data: { confirmed , recovered  , deaths , lastUpdate }} = await axios.get(changeableUrl);
       // console.log(response);
       return { confirmed , recovered  , deaths , lastUpdate };
    }
    catch(error) {

    }
}


export const fetchDailyData = async () => {
    try {
      
        const  { data } = await axios.get(url+'/daily');
     //   console.log(data);
     
        const modifyedData = data.map((dailyData) =>({
            confirmed: dailyData.confirmed.total,
            deaths: dailyData.deaths.total,
            date: dailyData.reportDate,
        }));
      
       return modifyedData;
    }
    catch(error) {

    }
}

export const fetchCountries = async () => {
    try {
      
        const {data: {countries}}  = await axios.get(url+'/countries');     
         //  console.log(data);
        return countries.map((country) => country.name);
      
       
    }
    catch(error) {

    }
}